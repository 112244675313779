import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { sendReviewerLinks, updateNewReviewers } from "@api/preview/send"
import { requiresWatermark, requiresPassword } from "@src/helpers/authorization"
import Warning from "../shared/warning"

const SharingOptionsFooter = ({
  status,
  showPasswordWarning,
  showWatermarkWarning,
  sendReviewerLinks,
  updateNewReviewers,
  maxViewCount,
  viewCount
}) => {
  const overViewCountLimit = Number(viewCount) > Number(maxViewCount) 
  return (
    <div className='mt3 right-align'>
      {showPasswordWarning && <Warning password/>}
      {showWatermarkWarning && <Warning watermark/>}
      {overViewCountLimit && <Warning viewCountLimit/>}
      <button
        className='cs-button white mr1'
        disabled={status === "PENDING"}
        onClick={() => updateNewReviewers("email", "step", "emails")}>
        Back
      </button>
      <button
        disabled={status === "PENDING" || showPasswordWarning || showWatermarkWarning || overViewCountLimit}
        className={`cs-button ${status === "PENDING" ? "pending" : ""}`}
        onClick={sendReviewerLinks}>
        Send Preview Links
      </button>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  status: state.preview.send.email.status,
  showPasswordWarning:
    requiresPassword(state.user.auth, state.preview.send.shareType) && !state.preview.send.email.password,
  showWatermarkWarning:
    requiresWatermark(state.user.auth, state.preview.send.shareType) && !state.preview.send.email.watermarkEnabled,
    viewCount: state.preview.send.email.view_count_limit,
    maxViewCount: state.user.auth.organization.max_view_count_limit
})

const mapDispatchToProps = dispatch => ({
  sendReviewerLinks: bindActionCreators(sendReviewerLinks, dispatch),
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

SharingOptionsFooter.propTypes = {
  sendReviewerLinks: PropTypes.func.isRequired,
  updateNewReviewers: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  showPasswordWarning: PropTypes.bool.isRequired,
  showWatermarkWarning: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(SharingOptionsFooter)
