import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers, generateSharingLink } from "@api/preview/send"
import { requiresWatermark, requiresPassword } from "@src/helpers/authorization"
import Watermarking from "../shared/watermarking"
import Password from "../shared/password"
import Notification from "../shared/notification"
import DateRestrictions from "../shared/date_restrictions"
import Warning from "../shared/warning"
import SingleIPProtection from "../shared/single_ip_protection"
import Geoblocking from "../shared/geoblocking"
import ViewRestrictions from "../shared/view_restrictions"
import ForensicWatermark from "../shared/forensic_watermark"
import Commenting from "../shared/commenting"

const URL = ({
  newReviewers,
  updateNewReviewers,
  generateSharingLink,
  checked,
  showPasswordWarning,
  showWatermarkWarning,
  canUseForensicWatermark,
  maxViewCount
}) => {
  const overViewCountLimit = Number(newReviewers.view_count_limit) > Number(maxViewCount)
  return (
  <div>
    <div className='flex items-start border-bottom border-gray-5 pb2'>
      <div className='col-4 pr2'>
        <label className='cs-label' htmlFor='name'>
          Link name
        </label>
        <input
          data-testid='share_by_url_name_input'
          id='name'
          value={newReviewers.full_name}
          className='cs-input col-12 block'
          placeholder='Film Festival Link'
          onChange={e => updateNewReviewers("link", "full_name", e.target.value)}/>
      </div>
    </div>
    <DateRestrictions stateKey='link' newReviewers={newReviewers}/>
    <ViewRestrictions stateKey='link' newReviewers={newReviewers}/>
    <Password stateKey='link'/>
    <SingleIPProtection
      onChange={e => updateNewReviewers("link", "ip_protection_enabled", !newReviewers.ip_protection_enabled)}
      checked={newReviewers.ip_protection_enabled}/>
    <Watermarking newReviewers={newReviewers} onUpdate={(key, value) => updateNewReviewers("link", key, value)}/>
    {canUseForensicWatermark && (
      <ForensicWatermark
        newReviewers={newReviewers}
        onUpdate={(key, value) => updateNewReviewers("link", key, value)}/>
    )}
    <Notification onChange={e => updateNewReviewers("link", "send_email_notification", !checked)} checked={checked}/>
    <Geoblocking newReviewers={newReviewers} onUpdate={(key, value) => updateNewReviewers("link", key, value)}/>
    <Commenting 
      onChange={e => updateNewReviewers("link", "commenting_enabled", !newReviewers.commenting_enabled)}
      checked={newReviewers.commenting_enabled}/>
    {showPasswordWarning && <Warning password/>}
    {showWatermarkWarning && <Warning watermark/>}
    {overViewCountLimit && <Warning viewCountLimit/>}
    {}
    <div className='flex justify-end mt3'>
      <button
        data-testid='share_by_url_generate_button'
        disabled={
          newReviewers.status === "PENDING" || !newReviewers.full_name || showPasswordWarning || showWatermarkWarning ||
          overViewCountLimit
        }
        className={`cs-button ${newReviewers.status === "PENDING" ? "pending" : ""}`}
        onClick={generateSharingLink}>
        Generate Sharing URL
      </button>
    </div>
  </div>
)}

const mapStateToProps = (state, context) => ({
  newReviewers: state.preview.send.link,
  checked: state.preview.send.link.send_email_notification,
  allowed: state.user.auth.can_password_protect_screener_groups,
  showPasswordWarning:
    requiresPassword(state.user.auth, state.preview.send.shareType) && !state.preview.send.link.password,
  showWatermarkWarning:
    requiresWatermark(state.user.auth, state.preview.send.shareType) && !state.preview.send.link.watermarkEnabled,
  canUseForensicWatermark: state.preview.send.can_use_forensic_watermark,
  maxViewCount: state.user.auth.organization.max_view_count_limit
})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch),
  generateSharingLink: bindActionCreators(generateSharingLink, dispatch)
})

URL.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  updateNewReviewers: PropTypes.func.isRequired,
  generateSharingLink: PropTypes.func.isRequired,
  allowed: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  showPasswordWarning: PropTypes.bool.isRequired,
  showWatermarkWarning: PropTypes.bool.isRequired,
  canUseForensicWatermark: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(URL)
