import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"

const ViewRestrictions = ({ viewCount, updateNewReviewers, stateKey, maxViewCount }) => {
  return (
    <div className='py2 border-bottom border-gray-5'>
      <div className='flex items-center'>
        <div className='col-8 flex items-center'>
          <div className='pl1'>
            <h4>View Count Limit</h4>
            <p className='mb0'>
              {`This will expire the link after ${viewCount} number of views. A session with at least 70% of the video watched is
              considered a view. This link supports a maxium of ${maxViewCount} views.`}
            </p>
          </div>
        </div>
        <div className='pl2 col-4'>
            <input
              id='viewCount'
              value={viewCount}
              type='number'
              autoFocus
              className={`cs-input col-12 block ${viewCount > Number(maxViewCount) ? "error" : ""}`}
              placeholder='Enter a number'
              onChange={e => updateNewReviewers(stateKey, "view_count_limit", e.target.value)}/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  viewCount: state.preview.send[ownProps.stateKey].view_count_limit,
  maxViewCount: state.user.auth.organization.max_view_count_limit

})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

ViewRestrictions.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  updateNewReviewers: PropTypes.func.isRequired,
  viewCount: PropTypes.string,
  stateKey: PropTypes.oneOf(["email", "link"]).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRestrictions)
